.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

ul {
  font-size: 80px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.AppLogo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.title {
  font-size: 70px;
}

.listContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.unsortedList {
  margin: 0;
}